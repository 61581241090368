import { useIntl, Link } from "gatsby-plugin-intl"
import { defaultIntl, UseQuery } from "../../utils"
import Layout from "../../components/layout"
import React, { useEffect, useState, useContext } from "react"
import { $get, showJobApplyForm } from "../../components/forms"
import "../../includes/jobs/jobs.sass"
import { globalContext } from "../../components/context"

export default () => {
  const intl = useIntl() || defaultIntl
  const [lang] = useState(intl.locale)
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [jobId] = useState(UseQuery().get("job_id"))
  const { country } = useContext(globalContext)
  useEffect(() => {
    $get(`/job-posts/${jobId}`)
      .then(({ data }) => {
        if (data) {
          setData(data)
        }
      })
      .catch(e => {
        alert(e.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const { notifies } = data

  return (
    <Layout intl={intl} page="job-detail">
      <div className="jobs section job-detail">
        <div className="container">
          <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to={`/?country=${country}`}>
                  <strong>{intl.formatMessage({ id: "job-detail.nav.home" })}</strong>
                </Link>
              </li>
              <li>
                <Link to={`/jobs/?country=${country}`}>{intl.formatMessage({ id: "job-detail.nav.jobs" })}</Link>
              </li>
              <li className="is-active">
                <a href="#" aria-current="page">
                  {intl.formatMessage({ id: "job-detail.nav.details" })}
                </a>
              </li>
            </ul>
          </nav>
          {isLoading ? (
            <p>{intl.formatMessage({ id: "global.loading" })}</p>
          ) : (
            <div className="container">
              <div className="columns">
                <div className="column is-half">
                  <div className="section-title" id="job-title">
                    {lang === "zh" ? data.title : data.title_en}
                  </div>
                  <div className="section-subtitle" id="job-location">
                    {data.city}
                  </div>
                  <div className="job-summary">{lang === "zh" ? data.summary : data.summary_en}</div>
                  <div className="btn-wrapper">
                    <button className="more-btn" onClick={() => showJobApplyForm({ intl, jobId, notifies })}>
                      {intl.formatMessage({ id: "job-detail.btn" })}
                      <i className="material-icons">chevron_right</i>
                    </button>
                  </div>
                </div>
                <div className="column is-half">
                  <h3>{intl.formatMessage({ id: "job-detail.res" })}</h3>
                  <div dangerouslySetInnerHTML={{ __html: lang === "zh" ? data.Responsibilities : data.Responsibilities_en }} />

                  <h3>{intl.formatMessage({ id: "job-detail.req" })}</h3>
                  <div dangerouslySetInnerHTML={{ __html: lang === "zh" ? data.Requirements : data.Requirements_en }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
